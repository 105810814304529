import React from 'react';
import { render } from 'react-dom';

import {
  BrowserRouter as Router
} from 'react-router-dom';

import App from 'screens/App/App.component';
import { UserProvider } from 'components/UserProvider/UserProvider.component.js';

document.addEventListener('DOMContentLoaded', function () {
  render(
    <UserProvider>
      <Router>
        <App/>
      </Router>
    </UserProvider>,
    document.getElementById('app')
  );
});
