import React from 'react';
import { Redirect } from 'react-router-dom';
import { authenticate as Auth } from 'config';

import LoginForm from './LoginForm.component';
import { UserConsumer } from 'components/UserProvider/UserProvider.component';

class Login extends React.Component {
  state = {
    error: ''
  }

  createSession = ({email, password}) => {
    Auth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        this.setState({ error: error.message });
      });
  }

  render () {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <UserConsumer>

        {({ user }) => (
          user ? (
            <Redirect to={{ pathname: '/' }} />
          ) : (
            <main css={{
              background: 'white',
              borderRadius: '3px',
              maxWidth: '550px',
              margin: 'auto',
              padding: '20px'
            }}>
              <h3>Sign in</h3>
              <LoginForm onSubmit={ this.createSession }/>
            </main>
          )
        )}
      </UserConsumer>
    );
  }
}

export default Login;
