import React from 'react';
import { UserConsumer } from 'components/UserProvider/UserProvider.component';

export default (Component) => (
  class extends React.Component {
    displayName = 'withUser';

    render () {
      return (
        <UserConsumer>
          {({ user }) => (
            <Component user={user} {...this.props}/>
          )}
        </UserConsumer>
      );
    }
  }
);
