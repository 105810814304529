import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import withUser from 'components/UserProvider/withUser';

class PrivateRoute extends React.Component {
  render () {
    const { children, user, ...rest } = this.props;
    return (
      (user === undefined)
        ? null
        : <Route {...rest } render={props =>
          user === null ? (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location }
            }}/>
          ) : (
            (user) ? children(props, user) : null
          )
        }/>
    );
  }
}

export default withUser(PrivateRoute);
