import React from 'react';

import {
  authenticate as auth
} from 'config';

const UserContext = React.createContext();

export const UserConsumer = UserContext.Consumer;
export class UserProvider extends React.Component {
  state = {
    user: undefined
  };

  componentDidMount () {
    auth.onAuthStateChanged((record) => {
      const user = (!record || !record.uid)
        ? null : record;
      this.setState({ user });
    });
  }

  render () {
    return (
      <UserContext.Provider value={ this.state }>
        { this.props.children }
      </UserContext.Provider>
    );
  }
}
