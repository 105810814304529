import React from 'react';

class LoginForm extends React.Component {
  state = {
    errors: {},
    validators: {
      email: {
        fn: (val) => (val.length),
        message: 'An Email is Required'
      },
      password: {
        fn: (val) => (val.length),
        message: 'A Password is Required'
      }
    },
    values: {
      email: '',
      password: ''
    }
  };

  onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { values } = this.state;

    this.setState({ values: {...values, [name]: value} });
  }

  validate (validators, values) {
    const errors = {};

    for (const [key, val] of Object.entries(values)) {
      const validator = validators[key];
      if (validator && !validator.fn(val)) {
        errors[key] = validator.message;
      }
    }

    return errors;
  }

  submit = (e) => {
    e.preventDefault();
    const { validators, values } = this.state;
    const newErrors = this.validate(validators, values);

    if (Object.keys(newErrors).length > 0) {
      this.setState({ errors: newErrors, open: true });
      return;
    }

    this.props.onSubmit(values);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { classes } = this.props;
    const { errors } = this.state;

    return (
      <form onSubmit={this.submit}>
        <div css={{
          marginBottom: '20px'
        }}>
          <label htmlFor="email" css={{
            display: 'block',
            fontSize: '11px',
            textTransform: 'uppercase',
            paddingBottom: '2px'
          }}>Email Address</label>
          <input onChange={this.onInputChange} id="email" name="email" autoComplete="email" autoFocus css={{
            width: '100%'
          }}/>
          {errors.email && <small css={{ fontSize: '11px', color: 'red'}}>{errors.email}</small>}
        </div>

        <div css={{
          marginBottom: '20px'
        }}>
          <label htmlFor="password" css={{
            display: 'block',
            fontSize: '11px',
            textTransform: 'uppercase',
            paddingBottom: '2px'
          }}>Password</label>
          <input onChange={this.onInputChange} name="password" type="password" id="password" autoComplete="current-password" css={{
            width: '100%'
          }}/>
          {errors.password && <small css={{ fontSize: '11px', color: 'red'}}>{errors.password}</small>}
        </div>

        <button type="submit">
          Sign in
        </button>
      </form>
    );
  }
}

export default LoginForm;
