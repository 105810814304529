import React from 'react';
import { ReadingsConsumer } from './ReadingsProvider.component';

export default (Component) => {
  return class extends React.Component {
    displayName = 'withReadings';

    render () {
      return (
        <ReadingsConsumer>
          {({ readings }) => {
            return (
              <Component
                readings={readings}
                {...this.props} />
            );
          }}
        </ReadingsConsumer>
      );
    }
  };
};
