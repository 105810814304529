import React from 'react';
import { Route } from 'react-router-dom';
import { Global } from '@emotion/core';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute.component';
import ReadingsProvider from 'components/ReadingsProvider/ReadingsProvider.component';
import Login from 'screens/Login';

import ReadingsSummary from './components/ReadingsSummary/ReadingsSummary.component';

function App () {
  return (
    <React.Fragment>
      <Global styles={{
        'body': {
          background: 'slategray',
          font: '100% normal helvetica, sans-serif',
          padding: '20px',
          margin: 0
        }
      }}/>

      <Route path="/login" component={Login} />

      <PrivateRoute exact path="/">
        {(props, user) => (
          <ReadingsProvider>
            <main css={{
              background: 'white',
              borderRadius: '3px',
              padding: '20px',
              maxWidth: '550px',
              margin: 'auto'
            }}>
              <h1 css={{
                borderBottom: '1px solid lightslategray',
                fontSize: '24px',
                fontWeight: 'normal',
                margin: '0 -20px 0',
                paddingBottom: '16px',
                textAlign: 'center'
              }}>Current Cabin Conditions</h1>
              <ReadingsSummary />
            </main>
          </ReadingsProvider>
        )}
      </PrivateRoute>
    </React.Fragment>
  );
}

export default App;
