import React from 'react';
import { database as db } from 'config';

const ReadingsContext = React.createContext();
export const ReadingsConsumer = ReadingsContext.Consumer;
export default class ReadingsProvider extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      humidity: undefined,
      temperature: undefined,
      battery: undefined
    };
  }

  componentDidMount () {
    this._loadReadings();
  }

  render () {
    return (
      <ReadingsContext.Provider value={ this.state }>
        { this.props.children }
      </ReadingsContext.Provider>
    );
  }

  _getReading (type) {
    return db.collection(type).orderBy('published_at', 'desc').limit(3)
      .get().then((snap) => {
        return {
          [type]: snap.docs.map(function(doc) {
            return {
              id: doc.id,
              ...doc.data()
            };
          })
        };
      });
  }

  _loadReadings (assignments) {
    const promises = ['humidity', 'temperature', 'battery'].map((collection) => {
      return this._getReading(collection);
    });

    Promise.all(promises).then((readings) => {
      this.setState(() => {

        const flatten = readings.reduce((acc, reading) => {
          const type = Object.keys(reading)[0];
          acc[type] = reading[type];
          return acc;
        }, {});
        return { readings: flatten };
      });
    });
  }
}
