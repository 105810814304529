import React from 'react';
import format from 'date-fns/format';
import map from 'lodash/map';

import withReadings from 'components/ReadingsProvider/withReadings';

function Reading ({ entries, type }) {
  function formatDate (date) {
    return format(date.toDate(), 'MMM. Do [at] h:mm A');
  }

  function formatData (data) {
    const fixed = parseFloat(data).toFixed(2);
    switch (type) {
      case 'temperature':
        return `${fixed} \u00b0F`
      default:
        return `${fixed}%`
    }
  }

  return (
    <React.Fragment>
      {entries.map((entry) => {
        return (
          <figure key={entry.id} css={{
            borderTop: '1px dotted lightslategray',
            display: 'block',
            lineHeight: 1.2,
            padding: '20px 0',
            margin: '0'
          }}>
            {formatData(entry.data)}
            <figcaption css={{
              fontSize: '11px',
              color: 'silver'
            }}>
              {formatDate(entry.published_at)}
            </figcaption>
          </figure>
        )
      })}
    </React.Fragment>
  );
}

function ReadingsSummary ({readings}) {
  return (
    <>
      {(readings)
        ? <>
            {map(readings, (val, key) => (
              <section key={key}>
                <h2 css={{
                  color: 'slategray',
                  fontSize: '12px',
                  marginTop: '40px',
                  textTransform: 'uppercase'
                }}>{key}</h2>
                <Reading entries={val} type={key}/>
              </section>
            ))}
          </>
        : <p>Loading Latest Readings</p>}
    </>
  )
}

export default withReadings(ReadingsSummary);
