import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: 'AIzaSyDKv6G2_6xOCHCeHsUXGS2ZZ-MKef1KPmI ',
  authDomain: 'coffee-and-smores-cabin.firebaseapp.com',
  projectId: 'coffee-and-smores-cabin'
});

const db = firebase.firestore();

export const authenticate = firebase.auth();
export { db as database };
